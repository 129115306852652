'use client';

import { Button } from '@/components/ui/button';
import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';
import { BaseNavbar } from '../components/navbar/base-navbar';
import { PageHeader } from '../components/page-header';
import { FooterWrapper } from '../components/page-wrappers/footer-wrapper';
import { MainContent } from '../components/page-wrappers/main-content';

// biome-ignore lint/style/noDefaultExport: This must be a default export
export default function ErrorPage({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);
  return <FooterWrapper data-sentry-element="FooterWrapper" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
			<BaseNavbar data-sentry-element="BaseNavbar" data-sentry-source-file="error.tsx" />

			<PageHeader title='Error' data-sentry-element="PageHeader" data-sentry-source-file="error.tsx" />

			<MainContent className='items-center justify-center gap-4' data-sentry-element="MainContent" data-sentry-source-file="error.tsx">
				<p>An error occurred while rendering this page</p>

				<Button onClick={reset} className='max-w-min' data-sentry-element="Button" data-sentry-source-file="error.tsx">
					Reload
				</Button>
			</MainContent>
		</FooterWrapper>;
}